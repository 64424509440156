@import "./utils.module";


@mixin reset {
    outline: none;
    border: none;
    box-shadow: none;
}

@mixin generate-shadows($root-color, $elevation: 1) {
    @if ($elevation == 0) {
        box-shadow: 0 1px 1px fade_out($root-color, 0.25),
        0 2px 2px fade_out($root-color, 0.20),
        0 4px 4px fade_out($root-color, 0.15),
    }

    @if ($elevation == 1) {
        box-shadow: 0 1px 1px fade_out($root-color, 0.25),
                    0 2px 2px fade_out($root-color, 0.20),
                    0 4px 4px fade_out($root-color, 0.15),
                    0 8px 8px fade_out($root-color, 0.10),
    }

    @if ($elevation == 2) {
        box-shadow: 0 1px 1px fade_out($root-color, 0.15),
                    0 2px 2px fade_out($root-color, 0.15),
                    0 4px 4px fade_out($root-color, 0.15),
                    0 8px 8px fade_out($root-color, 0.15);
    }

    @if ($elevation == 3) {
        $color: fade_out($root-color, 0.11);
        box-shadow: 0 1px 1px $color,
            0 2px 2px $color,
            0 4px 4px $color,
            0 8px 8px $color,
            0 16px 16px $color,
            0 32px 32px $color,
    }
}

@mixin generate-button($button-color, $text-color, $withShadows: true, $large: false) {
    $text-shadow: 0 1px 1px scale_color($button-color, $lightness: -10%);
    $shadow-base: scale_color(scale_color(scale_color($button-color, $lightness: 25%), $alpha: 10%), $saturation: 5%);
    $root-shadow-color: scale_color(scale_color($shadow-base, $saturation: -70%), $lightness: 60%);
    $button-height: 40px;


    $icon-spacing: 0.7rem;
    $scale: 1;

    @if ($large) {
        $scale: 1.2;
        $button-height: 50px;
    }

    @include reset;
    @include flex(row, center, center);
    background-color: $button-color;
    color: $text-color;
    height: $button-height;
    padding: 0 1rem * $scale;
    border-radius: $button-height * 2;
    font-size: 0.9rem * $scale;
    cursor: pointer;
    text-shadow: $text-shadow;
    transition: box-shadow 150ms, background-color 150ms;


    @if ($withShadows) {
        @include generate-shadows($root-shadow-color, 1);
    }


    &:hover {
        @if ($withShadows) {
            @include generate-shadows($root-shadow-color, 0);
        }

        background-color: scale_color($button-color, $lightness: -10%);
    }

    .rightIcon, .leftIcon {
        filter: drop-shadow(#{$text-shadow});
    }


    .leftIcon + span {
        margin-left: $icon-spacing;
    }

    span + .rightIcon {
        margin-left: $icon-spacing;
    }

    &.circle {
        width: $button-height;
    }

}

.button {
    @include generate-button($cta-color, white, true);

    &.primary {
        &.large {
            @include generate-button($cta-color, white, true, true);
        }

        &.minimal {
            $base-color: scale_color($heading-color, $alpha: -95%);

            @include generate-button($base-color, $heading-color, false);

            &.large {
                @include generate-button($base-color, $heading-color, false, true);
            }

            &:hover {
                background-color: scale_color(scale_color($base-color, $lightness: -90%), $saturation: 20%);
            }
        }

    }

    &.danger {
        @include generate-button($danger-color, white);

        &.large {
            @include generate-button($danger-color, white, true, true);
        }

        &.minimal {
            $base-color: scale_color($danger-color, $alpha: -90%);
            $text-color: scale_color($danger-color, $lightness: -20%);

            @include generate-button($base-color, $text-color, false);

            &.large {
                @include generate-button($base-color, $text-color, false, true);
            }
        }
    }

}
