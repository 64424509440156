@import "../../common/utils.module";

.globalLoading {
    @include flex(column, center, center);
    height: 99%;
    width: 99%;
    overflow: hidden;
}

@keyframes spin {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg) }
}

.spinner {
    @include flex(row, center);
    position: relative;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: spin 1s linear infinite;
    }
}