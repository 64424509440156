@import "../../common/utils.module";

.registration-form {
    @include flex(column);
    flex: 1;
    overflow-y: hidden;

    .form-container {
        flex: 1;
        padding: 1rem;
        overflow-y: auto;
    }

    .step-actions {
        @include flex(row, center, space-between);
        flex-shrink: 0;
        border-top: 1px solid $divider-color;
        padding: 0.5rem 1rem;
    }

}