@import "../../common/utils.module";

.questionList {
    $list-vertical-padding: 2rem;

    @include flex(column);
    flex: 1;
    margin-bottom: 1rem;
    padding: 1rem;
    overflow-y: auto;

    .questions {
        flex: 1;
        overflow: auto;
    }

    .questionListItem {
        position: relative;
        border-bottom: 1px solid $divider-color;
        margin-bottom: $list-vertical-padding;
        padding-bottom: $list-vertical-padding / 2;
        transition: color 150ms;

        &:active {
            color: $brand-color;
        }

        &:hover {
            color: $brand-color;
        }

        &:last-child {
            border-bottom: none;
        }

        .questionContent {
            margin-bottom: 0.5rem;
        }

        .answerCheck {

        }
    }

    .focusedQuestion {
        @include flex(column);
        flex: 1;

        .focusedQuestionContent {
            margin-bottom: 1rem;
        }

        .answerInput {
            flex: 1;
            margin-bottom: 1rem;
        }

        .imageDescriptionInput {
            margin-top: 2rem;
            flex: 1
        }

        .imageUploadContainer {
            @include flex(column, center, center);
            flex: 1;
        }

        .imageUploadActions {
            @include flex(row, center, space-around);
        }

        .imageUploadButton {
            @include flex(row, center, center);
            background-color: $cta-color;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 2rem;

            input {
                display: none;
            }
        }
    }
}
