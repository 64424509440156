@import "../../common/utils.module";

.thankYou {
    @include flex(column, space-between, center);
    @include background-shift(#3b6064, #05c39a);

    flex: 1;
    text-align: center;
    padding: 4rem 1rem;

    .directions {
        padding: 1rem;

        h3, p {
            color: white;
        }

    }


    .footer {
        @include flex(column, center, center);
    }

    .interestCta {
        @extend %sans-serif;
        margin-top: 1rem;
        opacity: 0.8;
        color: white;
        padding: 0.5rem;
    }
}