@import "../common/utils.module";

.survey {
    @include flex(column);
    flex: 1;
    overflow-y: hidden;
}

.steps {
    padding: 1rem;
}

.stepActions {
    @include flex(row, center, space-between);
    padding: 0.5rem 1rem;
    flex-shrink: 0;
}
