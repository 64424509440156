@import "../common/utils.module";

$badge-size: 70px;
$sidebar-width: 150px;
$padding-top: 2rem;

.curator {
    background-color: $background-color;

    .pageContent {
        padding: 2px;
    }

    .cardGrid {
        @include flex(row);
        flex-wrap: wrap;
    }
}

.curatorView {
    @include flex(row, flex-start, stretch);
    align-self: center;
    height: 100%;
    width: 100%;
    margin: 0 1rem;
    max-width: 70rem;

    .titleBar {
        @include flex(row, center, space-between);
        margin-bottom: 2rem;
        margin-top: -0.25rem;
    }

    .pageTitle {
        @extend %serif;
    }

    .badge {
        @include flex(row, center, center);
        background-color: $brand-color;
        border-radius: $badge-size * 2;
        width: $badge-size;
        height: $badge-size;
    }

    .sideBar {
        @include flex(row, center, space-between);
        color: $brand-color;
        position: relative;
        margin-right: 1rem;
        flex: 0 0 $sidebar-width;
        padding-top: $padding-top;
    }

    .contentArea {
        padding-top: $padding-top;
        flex: 1;
        overflow-y: auto;
        align-self: stretch;
    }
}

.userAccount {
    @include flex(row, center);

    .userAvatar {
        margin-right: 10px;
    }
}
