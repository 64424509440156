
@import "./utils.module";

.layout {
    &.row {
        @include flex(row, center);

        &.space-between { @include flex(row, center, space-between); }
        &.flex-start { @include flex(row, center, flex-start); }
        &.flex-end { @include flex(row, center, flex-end); }
    }

    &.column {
        @include flex(column, center);

        &.space-between { @include flex(row, center, space-between); }
        &.flex-start { @include flex(row, center, flex-start); }
        &.flex-end { @include flex(row, center, flex-end); }
    }

    &.marginRight1 { margin-right: 1rem; }
    &.marginLeft1 { margin-left: 1rem; }
    &.marginTop1 { margin-top: 1rem; }
    &.marginBottom1 { margin-bottom: 1rem; }

    &.marginRight2 { margin-right: 2rem; }
    &.marginLeft2 { margin-left: 2rem; }
    &.marginTop2 { margin-top: 2rem; }
    &.marginBottom2 { margin-bottom: 2rem; }
}


.rowComponent {
    @include flex(row, center);
}

.columnComponent {
    @include flex(column);
}

.modalFooterActions {
    @include flex(row, center, space-between);
    border-top: 1px solid $divider-color;
    padding-top: 1rem;
    margin-top: 1rem;
}
