@import "../common/utils.module";

.login {
    @include flex(column, center, center);
    height: 100%;

    .login-panel {
        @include card(2);
        padding: 2rem;
        width: 400px;

        .input {
            margin-bottom: 2rem;
        }
    }
}