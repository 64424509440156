@import "../../common/utils.module";

.intro {
    @include flex(column);
    padding: 1rem;
    overflow-y: auto;

    .welcome-text {
        @include flex(row, center);
        margin-top: 3rem;
        margin-bottom: 3rem;
        position: relative;

        .ground {
            position: absolute;
            min-width: 1.5rem;
            bottom: -1.7rem;
            left: -2rem;
            z-index: 0;
        }

        span {
            @extend %serif;
            @extend %italic;
            @extend %medium;
            border-radius: 5rem;
            padding: 0.5rem 1rem;
            z-index: 1;
        }
    }

    .content-area {
        position: relative;
    }

    .next-btn {
        margin-top: 1rem;
    }

    .mountain {
        position: absolute;
        //min-height: 3px;
        min-width: 4rem;
        bottom: 1.2rem;
        right: 1rem;
    }

    .sun {
        position: absolute;
        width: 3.5rem;
        bottom: 4.2rem;
        right: 7rem;
        z-index: 0;
    }

    .logo {
        width: 10rem;
        margin: 0;
        margin-right: 0rem;
        z-index: 1;
    }

    .mark {
        width: 4rem;
        margin: 0;
        margin-right: 0rem;
        z-index: 1;
    }
}