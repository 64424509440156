@import "../common/utils.module";


.register {
    @include flex(column, center, center);
    height: 100%;

    .register-panel {
        @include card(2);
        padding: 2rem;
        width: 600px;

        .input {
            margin-bottom: 2rem;
        }
    }
}