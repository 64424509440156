@import "../common/utils.module";

.edition {
    $author-color: $brand-color;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    .questionScrollItem {
        @include flex(row);
        height: 100%;
        width: 100%;
        position: absolute;
    }

    $scroll-actions-height: 70px;
    .scrollWindow {
        @include flex(column, flex-start);
        width: 100%;
        position: relative;
    }

    .authorContainer {
        @include flex(row, center, center);
        position: relative;
        width: 100%;
        height: 100%;
    }

    .authorScrollItem {
        @include flex(column, center, center);
        height: calc(100% - #{$scroll-actions-height});
        width: 100%;
        position: absolute;
    }

    .scrollItem {
        height: calc(100% - #{$scroll-actions-height});
        width: 100%;
        position: absolute;
    }

    .scrollActions {
        @include flex(row, center, space-between);
        padding: 1rem;
        width: 100%;
        height: $scroll-actions-height;
        position: absolute;
        bottom: 0;
        border-top: 1px solid $divider-color;
    }

    .questionCoverPage {
        @include flex(column, space-between);
        padding: 2rem;

        .questionNumber {
            @extend %serif;
            @extend %heavy;
            @extend %small-font;
            //text-transform: uppercase;
            color: $muted-text;
            font-style: normal;
        }

        .openQuestionButton {
        }

        h1 {
            @extend %serif;
            @extend %italic;
        }
    }

    %scroller-card {
        @include flex(column);
        background-color: white;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, .1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, .2);
        border-radius: 4px;
        width: 80vw;
        max-width: 500px;
        flex-shrink: 0;
        max-height: calc(100% - 3rem);
        overflow: hidden;
    }

    .answer {
        @include flex(column);
        height: 100%;

        .answerContent {
            @extend %serif;
            flex: 1;
            font-size: 1.1rem;
            line-height: 1.8rem;
            overflow: auto;
            white-space: pre-line;
            padding: 1rem;
            padding-top: 2rem;
            padding-bottom: 3rem;
            position: relative;
        }

        .image {
            min-width: 100%;
            min-height: 200px;
            border-radius: 4px;
        }

        .scrollContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            padding: 1.5rem;
        }

        .answerAuthor {
            @include flex(row, center, space-between);
            @extend %sans-serif;
            position: relative;
            flex-shrink: 0;
            color: $author-color;
            padding: 0.5rem 0;
            margin: 0 1rem;
            height: 3rem;

            .answerScrollMask {
                $mask-height: 90px;
                pointer-events: none;
                position: absolute;
                //top: calc(-#{$mask-height} - 1px);
                top: calc(-#{$mask-height});
                left: 0;
                height: $mask-height;
                width: 100%;
                background: linear-gradient(0deg, fade_out($background-color, 0) 0%, fade_out($background-color, 1) 70%);

            }


            .authorLocation {
                font-size: 0.85rem;
                margin-left: 1.3rem;
            }
        }

        .feedbackActions {
            @include flex(row, center);
        }

        .feedbackAction {
            @include flex(row, center, center);
            flex: 1;
            border-radius: 2px;
            padding: 0.5rem;
            transition: transform 150ms ease-out;
            transform: scale(1);

            &:active {
                transform: scale(0.7);
            }

            &:hover {
                transform: scale(0.7);
            }
        }
    }

    .sideScroller {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-left: 1rem;
    }

    .scrollingItem {
        height: 100%;
        margin-right: 0rem;
        // Necessary to prevent shadow clipping
        padding: 2px;
    }

    .scrollSpacer {
        min-width: 10vw;
        min-height: 2rem;
    }


    .readingThankYou {
        @include flex(column, space-between, center);
        @include background-shift(#3b6064, #05c39a);
        position: relative;

        overflow: hidden;
        flex: 1;
        color: white;

        .messaging {
            padding: 1rem;
        }

        .logo {
            @include flex(row, center, center);
            margin-bottom: 2rem;
        }

        #{$headers} {
            color: white;
        }

        .authorCard {
            @extend %scroller-card;
            color: $heading-color;
            padding: 1rem;

        }
    }

    .rating {
        text-align: center;
        margin-bottom: 2rem;
    }

    .editionTitle {
        @include flex(column, space-between);
        width: 100%;
        padding: 2rem;

        .heroImageContainer {
            @include flex(row, center, center);
        }

        .instructions {
            opacity: 0.8;
        }

        border-bottom: 2px solid $cta-color;
    }

}
