@import "../../common/utils.module";

.circleCard {
    @include circle-card(false);

    &.interactive {
        @include circle-card(true);
    }

    .circleName {
        @include card-row(1rem, 1rem, 0.5rem, 1rem);
        @include flex(column, center, stretch);
        @extend %medium;
    }

    .circleUpdatedAt {
        @extend %very-small-font;
    }

    .circleStatus {
        @include card-row(0.5rem, 1rem, 1rem, 1rem);
    }
}


