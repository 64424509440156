@import "../../common/utils.module";

.contributorsManager {

    .contributorListItem {
        @include flex(row, center, space-between);

        .basicInfo {
            @include flex(column, center, flex-start);
        }

        .contact {
            @extend %small-font;
            color: $muted-text;
        }

        .inviteStatus {
            color: $muted-text;
        }
    }

}