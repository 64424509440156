/**
Selectors
 */
$headers: "h1, h2, h3, h4, h5, h6";
$body: "p, span";
$list: "ul, ol";


/**
Colors
 */
$background-color: #fffbf4;
$heading-color: #1D555C;
$brand-color: #3B6064;
$divider-color: fade_out($heading-color, 0.8);
$interactivity-color: #F5F8FA;
$cta-color: #05c39a;
$tan-accent-color: #FFEEDD;
$muted-text: desaturate(fade_out($heading-color, 0.2), 0.2);
$danger-color: #F45B69;


/**
Typography
 */
$sans-serif: "Rubik";
$serif: "Gelasio";

%sans-serif { font-family: $sans-serif, sans-serif !important; }
%serif { font-family: $serif, sans-serif !important; }
%light { font-weight: 300 !important; }
%default { font-weight: 400 !important; }
%medium { font-weight: 500 !important; }
%heavy { font-weight: 600 !important; }
%black { font-weight: 700 !important; }
%italic { font-style: italic !important; }
%small-font { font-size: 0.8rem; }
%very-small-font { font-size: 0.7rem; }
%large-font { font-size: 1.2rem; }

.sans-serif { @extend %sans-serif; }
.serif { @extend %serif; }
.light { @extend %light; }
.default { @extend %default; }
.medium { @extend %medium; }
.heavy { @extend %heavy; }
.black { @extend %black; }
.italic { @extend %italic; }
.small-font { @extend %small-font }
.very-small-font { @extend %very-small-font }
.large-font { @extend %large-font }

ul {
    list-style: none;
    margin-left: 0;
}

.btn-fill {
    width: 100%;
}

/**
Layout
 */
@mixin flex($direction: row, $verticalAlignment: "", $horizontalAlignment: "") {
    display: flex;
    flex-direction: $direction;

    @if ($direction == row ) {
        @if ($verticalAlignment != "") {
            align-items: $verticalAlignment;
        }
        @if ($horizontalAlignment != "") {
            justify-content: $horizontalAlignment;
        }
    }

    @if ($direction == column) {
        @if ($horizontalAlignment != "") {
            align-items: $horizontalAlignment;
        }
        @if ($verticalAlignment != "") {
            justify-content: $verticalAlignment;
        }
    }
}

// Taken from Blueprint
@mixin elevation($elevation: 1) {
    @if ($elevation == 0) {
        box-shadow: 0 0 0 1px rgba(16,22,26,.15), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);
    }
    @if ($elevation == 1) {
        box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 0 0 rgba(16,22,26,0), 0 1px 1px rgba(16,22,26,.2);
    }
    @if ($elevation == 2) {
        box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 1px 1px rgba(16,22,26,.2), 0 2px 6px rgba(16,22,26,.2);
    }
    @if ($elevation == 3) {
        box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 2px 4px rgba(16,22,26,.2), 0 8px 24px rgba(16,22,26,.2);
    }
    @if ($elevation == 4) {
        box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 4px 8px rgba(16,22,26,.2), 0 18px 46px 6px rgba(16,22,26,.2);
    }
}

@mixin card($elevation: 1, $interactive: false) {
    background-color: white;
    border-radius: 8px;
    @include elevation($elevation);

    @if ($interactive) {
        cursor: pointer;
        transition: box-shadow 150ms;

        &:hover {
            @include elevation($elevation + 1);
        }

        &:active {
            @if ($elevation > 0) {
                @include elevation($elevation - 1);
            } @else {
                box-shadow: none;
            }
        }
    }
}

@mixin card-row($top: 1rem, $right: 1rem, $bottom: 1rem, $left: 1rem) {
    @include flex(row, center);
    padding: $top $right $bottom $left;
}

/**
Animations
 */
@keyframes BackgroundShift {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@mixin background-shift($color1, $color2, $duration: 4s, $angle: 262deg) {
    background: linear-gradient($angle, $color1, $color2);
    background-size: 400% 400%;
    animation: BackgroundShift $duration ease infinite;
}


/**
Shared components
 */

$circle-card-size: 10rem;
$circle-grid-spacing: 2rem;

@mixin circle-card($interactive: false) {
    @include card(1, $interactive);
    @include flex(column, center, center);
    width: $circle-card-size;
    height: $circle-card-size;
    border-radius: $circle-card-size * 2;
    margin-right: $circle-grid-spacing;
    margin-bottom: $circle-grid-spacing;
    text-align: center;
}


/**
Shared layouts
 */

.cardGrid {
    @include flex(row);
    flex-width: wrap;
    padding: 2px;
}

