@import "../../common/utils.module";

.questionAndResponses {
    padding: 2px;
    height: 500px;
    overflow: hidden;

    .authorAndAnswer {
        @include card(1);
        flex: 1;
        overflow: hidden;
    }

    .contentArea {
        overflow: hidden;
    }

    .authorSelection {
        border-right: 1px solid $divider-color;
        width: 500px;
        align-self: stretch;
        overflow-y: auto;

        .authorContainer {
            @include flex(row, center, space-between);
            padding: 1rem;
            cursor: pointer;
            background-color: white;
            transition: background-color 150ms;

            &:hover {
                background-color: $interactivity-color;
            }

            &.active {
                background-color: $brand-color;
                color: white;
            }

            &.hidden {
                opacity: 0.7;
            }
        }
    }

    .focusedAnswer {
        align-self: stretch;
        flex: 1;
        padding: 1rem;
        overflow-y: auto;
    }

    .responseList {
        @include flex(row);
        overflow-x: auto;
    }

    .response {
        min-width: 400px;
        max-height: 500px;
        overflow: auto;
        border: 1px solid $divider-color;
        padding: 2rem;
        margin: 1rem;
    }

    .curationActions {
        border-top: 1px solid $divider-color;
        padding: 1rem;
    }

    .curationActionbuttons {
        margin-left: 1rem;

        > button {
            margin-right: 0.5rem;
        }
    }
}
