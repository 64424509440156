@import "../common/utils.module";

.circles {

    .createCircleCard {
        @include circle-card(true);
        @include flex(column, center, center);
        font-size: larger;

        h4 {
            margin-top: 1rem;
        }
    }
}