@import "../../common/utils.module";

.events {
    @include flex(column, space-between);
    padding: 2rem;
    border-bottom: 2px solid $cta-color;

    .editors-note {
        @extend %sans-serif;
        @extend %light;
        opacity: 0.8;
        border: 1px solid $divider-color;
        border-radius: 4px;
        padding: 0.5rem;
    }
}
