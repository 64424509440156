@import "../../common/utils.module";


.contributionForm {
    @include flex(column);
    flex: 1;
    overflow-y: auto;

    .stepActions {
        @include flex(row, center, space-between);
        flex-shrink: 0;
        border-top: 1px solid $divider-color;
        padding: 0.5rem 1rem;
    }
}

