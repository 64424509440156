@import "../../common/utils.module";

$edition-card-width: 150px;
$edition-card-height: 200px;
$edition-grid-spacing: $circle-grid-spacing;

%edition-card {
    @include card(1, false);
    width: $edition-card-width;
    height: $edition-card-height;
    margin-right: $edition-grid-spacing;
    margin-bottom: $edition-grid-spacing;
}

.edition-card {
    @extend %edition-card;
    @include flex(column, space-between);

    &.interactive {
        @include card(1, true);
    }

    .title {
        @include card-row();
    }

    .updated-at {
        @include card-row();
        @extend %very-small-font;
    }
}

.create-edition-card {
    @extend %edition-card;
    @include card(1, true);
    @include flex(column, center, center);
    text-align: center;
    font-size: larger;

    h4 {
        margin-top: 1rem;
    }
}
