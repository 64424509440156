@import "../common/utils.module";

.editionEditor {

    .tokenContainer {
        @include card(1);
        padding: 1rem;
        margin-top: 3rem;
    }

    .surveyEditingActions {
        margin-top: 3rem;
    }

    .newsletterPreview {
        width: 400px;
        height: 700px;
        overflow: hidden;
        border: 2px solid red;
    }
}


.surveyEditor {
    padding: 2px;

    .questionsList {
        @include card(1);
        margin-top: 3rem;
    }

}

