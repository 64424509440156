@import "./common/utils.module";

body, button, div, h1, h2, h3, h4, h5, h6, span, a, input, label {
    @extend %sans-serif;
    @extend %default;
}

p {
    @extend %serif;
}

$streamline-selector: ".Streamline_Icon";

:global {
    button.ant-btn {
        @include flex(row, center, center);
        border-radius: 5px !important;

        > #{$streamline-selector} {
            display: inline-flex !important;
            align-self: center;
            justify-self: center;

            & + span {
                margin-left: 10px;
            }
        }
    }

    .ant-input {
        border-radius: 2rem !important;
        padding: 0.5rem 1rem !important;
    }

    textarea.ant-input {
        padding: 1rem !important;
        border-radius: 8px !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon,
    .ant-steps-item-wait .ant-steps-item-icon {
        background-color: $background-color !important;
    }

}

.app {
    @include flex(column);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $background-color;

    .contributor {
        background-color: $background-color;

    }
}




